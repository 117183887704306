<template>
  <div class="header">
    <div class="topbar">
      <div style="color: #8f8b7a" class="py-4">
        GIVE US A CALL 24/7 AT
        <span class="text-gray-300"> +960 7944493 </span> OR DROP US AN EMAIL AT
        <span class="text-gray-300"> RES@AMAZINGASIATRAVELS.COM </span>
      </div>
      <div style="color: #8f8b7a" class="right-0 flex">
        <p class="p-4">FOLLOW US ON:</p>
        <div class="px-3 p-4" style="background-color: #1f2427">
          <a href="https://www.instagram.com/amazingasiatravels/?hl=en"
            ><svg
              fill="white"
              width="16px"
              height="16px"
              role="img"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Instagram</title>
              <path
                d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
              /></svg
          ></a>
        </div>
        <div class="px-3 p-4 ml-1" style="background-color: #1f2427">
          <a href="https://facebook.com/amazingasiatravel">
            <svg
              fill="white"
              width="16px"
              height="16px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              stroke="#fff"
              stroke-width="0"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
              ></path>
            </svg>
          </a>
        </div>

        <div class="px-3 p-4 ml-1" style="background-color: #1f2427">
          <a href="https://twitter.com/amazingasia_">
            <svg
              fill="white"
              width="16px"
              height="16px"
              role="img"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Twitter</title>
              <path
                d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
              /></svg
          ></a>
        </div>

        <div class="px-3 p-4 ml-1" style="background-color: #1f2427">
          <a
            href="https://www.youtube.com/channel/UCS3CChX0NY5R4tWvU58naJg/videos"
          >
            <svg
              fill="white"
              width="16px"
              height="16px"
              role="img"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>YouTube</title>
              <path
                d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
              /></svg
          ></a>
        </div>
      </div>
    </div>
  </div>

  <div class="header-container md:px-20 px-4">
    <div class="max-w-6xl mx-auto w-full flex justify-between items-center">
      <div class="flex items-center flex-shrink-0 text-white mr-6">
        <router-link
          class="text-white no-underline hover:text-white hover:no-underline"
          to="/"
        >
          <img class="h-14 py-2" src="@/assets/img/logot.png" />
        </router-link>
      </div>

      <div class="block lg:hidden">
        <button
          id=""
          @click="showMenu"
          class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-white hover:border-white"
        >
          <svg
            class="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>

      <div
        class="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block pt-6 lg:pt-0"
        id="nav-content"
      >
        <ul
          style="color: #bfc0c0"
          class="list-reset lg:flex justify-end flex-1 items-center"
        >
          <li class="mr-3">
            <a
              class="inline-block py-2 px-4 text-xs no-underline"
              href="/properties"
              >RESORTS</a
            >
          </li>
          <li class="mr-3">
            <a
              class="inline-block no-underline text-xs hover:text-gray-200 hover:text-underline py-2 px-4"
              :href="`/properties?type=${$lhotel_type_id}`"
              >LOCAL HOTELS</a
            >
          </li>
          <li class="mr-3">
            <a
              class="inline-block no-underline text-xs hover:text-gray-200 hover:text-underline py-2 px-4"
              href="/honeymoon"
              >HONEYMOONS</a
            >
          </li>
          <!-- <li class="mr-3">
            <a
              class="
                inline-block
                no-underline
                text-xs
                hover:text-gray-200 hover:text-underline
                py-2
                px-4
              "
              href="#"
              >FAMILY</a
            >
          </li> -->
          <li class="mr-3">
            <a
              class="inline-block no-underline text-xs hover:text-gray-200 hover:text-underline py-2 px-4"
              href="/tales"
              >TALES</a
            >
          </li>
          <li class="mr-0">
            <a
              class="inline-block no-underline text-xs hover:text-gray-200 hover:text-underline py-2 pl-4"
              href="/special-offers"
              >SPECIAL OFFERS</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- MOBILE MENU -->

  <nav class="" v-show="navVisible">
    <button class="close" @click="hideMenu">
      <svg
        width="50px"
        height="50px"
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="close"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <circle id="Oval" stroke="#FFFFFF" cx="50" cy="50" r="49.5"></circle>
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            transform="translate(50.000000, 50.000000) rotate(45.000000) translate(-50.000000, -50.000000) "
            x="25"
            y="49"
            width="50"
            height="2"
          ></rect>
          <rect
            id="Rectangle-Copy-2"
            fill="#FFFFFF"
            transform="translate(50.000000, 50.000000) rotate(-45.000000) translate(-50.000000, -50.000000) "
            x="25"
            y="49"
            width="50"
            height="2"
          ></rect>
        </g>
      </svg>
    </button>

    <ul class="text-white text-2xl text-center uppercase">
      <li class="py-4"><a href="/properties">Resorts</a></li>
      <li class="py-4">
        <a :href="`/properties?type=${$lhotel_type_id}`">Local Hotels</a>
      </li>
      <li class="py-4"><a href="/honeymoon">Honeymoons</a></li>
      <!-- <li class="py-4"><a href="">Family</a></li> -->
      <li class="py-4"><a href="/tales">Tales</a></li>
      <li class="py-4"><a href="/special-offers">Special Offers</a></li>
    </ul>
  </nav>

  <!-- MOBILE MENU // -->
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      navVisible: false,
    };
  },
  methods: {
    showMenu: function () {
      console.log("show");
      this.navVisible = true;
      console.log(this.navVisible);
    },
    hideMenu: function () {
      console.log("hide");
      this.navVisible = false;
      console.log(this.navVisible);
    },
  },
};
</script>

<style scoped>
/* Buttons */

#hamburger,
.close {
  display: inline-block;
  background: black;
  padding: 0;
  margin: 0;
  right: 0;
  border: none;
  margin: 2rem auto 3rem;
  cursor: pointer;
}

button:hover svg g circle {
  stroke: palegreen;
}
button:hover svg g rect {
  fill: palegreen;
}

button.close:hover svg g circle {
  stroke: tomato;
}
button.close:hover svg g rect {
  fill: tomato;
}

/* Nav */

nav {
  position: absolute;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: rgb(0, 0, 0, 0.9);
  backdrop-filter: blur(1px);
}
</style>
