import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import TheHeader from "./components/base/TheHeader";
import TheFooter from "./components/base/TheFooter";
import Spinner from "./components/base/Spinner";

import "@/tailwind/index.tw.css";

import VueSplide from "@splidejs/vue-splide";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";
// // or
// import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
// // or
// import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import errorHandler from "./utils/error";
import axios from "axios";
import formatDate from "./utils/formatDate";

const app = createApp(App)
  .use(store)
  .use(router);

app.component("TheHeader", TheHeader);
app.component("TheFooter", TheFooter);
app.component("Spinner", Spinner);

app.use(VueSplide);

app.config.globalProperties.$errorHandler = errorHandler;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$api_v1 = process.env.VUE_APP_API_V1;
app.config.globalProperties.$api = process.env.VUE_APP_API;

// db ids
app.config.globalProperties.$resort_type_id =
  process.env.VUE_APP_RESORT_PTYPE_ID;
app.config.globalProperties.$lhotel_type_id =
  process.env.VUE_APP_LOCAL_HOTEL_PTYPE_ID;

app.mount("#app");
