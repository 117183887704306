import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/components/pages/Home"),
  },
  {
    path: "/properties",
    name: "Resorts",
    component: () => import("@/components/pages/Properties"),
  },
  {
    path: "/properties/page/:pageid(\\d+)",
    name: "ResortsP",
    component: () => import("@/components/pages/Properties"),
  },
  {
    path: "/properties/:id",
    name: "SingleProperty",
    component: () => import("@/components/pages/SingleProperty"),
  },
  {
    path: "/honeymoon",
    name: "Honeymoon",
    component: () => import("@/components/pages/Honeymoon"),
  },
  {
    path: "/tales",
    name: "Tales",
    component: () => import("@/components/pages/Tales"),
  },
  {
    path: "/tales/page/:pageid(\\d+)",
    name: "TalesP",
    component: () => import("@/components/pages/Tales"),
  },
  {
    path: "/tales/:slug",
    name: "TalesSingle",
    component: () => import("@/components/pages/TalesSingle"),
  },
  {
    path: "/enquiry",
    name: "Enquiry",
    component: () => import("@/components/pages/Enquiry"),
  },
  {
    path: "/special-offers",
    name: "Offers",
    component: () => import("@/components/pages/SpecialOffers"),
  },
  {
    path: "/special-offers/page/:pageid(\\d+)",
    name: "OffersP",
    component: () => import("@/components/pages/SpecialOffers"),
  },

  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(function(to, from, next) {
  to;
  from;
  window.scrollTo(0, 0);
  next();
});

export default router;
