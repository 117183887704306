<template>
  <TheHeader></TheHeader>
  <router-view />
  <TheFooter></TheFooter>
</template>


<style lang="scss" >
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.slim-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}
.slim-scrollbar::-webkit-scrollbar {
  width: 16px;
}

.text-section h1,
.text-section h2,
.text-section h3,
.text-section h4,
.text-section h5 {
  text-transform: uppercase;
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}
.text-section p {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  padding: 2.5rem 0 2.5rem 0;
}

.qview-description h1,
.qview-description h2,
.qview-description h3,
.qview-description h4,
.qview-description h5,
.qview-description h6 {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  font-weight: 600 !important;
}

.qview-description h1:not(:first-child),
.qview-description h2:not(:first-child),
.qview-description h3:not(:first-child),
.qview-description h4:not(:first-child),
.qview-description h5:not(:first-child),
.qview-description h6:not(:first-child) {
  margin-top: 0.5rem;
}

.input-error {
  border-bottom-color: rgb(182, 41, 41) !important;
}

@font-face {
  font-family: "Brown Regular";
  src: url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot");
  src: url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.svg#Brown Regular")
      format("svg");
}
</style>