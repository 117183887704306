<template>
  <div
    :style="{ visibility: loading ? 'visible' : 'hidden' }"
    class="w-7 h-7 flex items-center"
  >
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: ["loading"],
};
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 85%;
  height: 85%;
  margin: 3px;
  border: 3px solid #a29c88;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #a29c88 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>